import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from '../directive/directive.module';
import { BackButtonComponent } from './back-button/back-button.component';
import { ClearInputIconComponent } from './clear-input-icon/clear-input-icon.component';
import { CsChatIconComponent } from './cs-chat-icon/cs-chat-icon.component';
import { CustomModalComponent } from './custom-modal/custom-modal.component';
import { CollapsiblePanelComponent } from './dynamic-template/collapsible-panel/collapsible-panel.component';
import { DynamicTemplateComponent } from './dynamic-template/dynamic-template.component';
import { InlinePreloaderComponent } from './inline-preloader/inline-preloader.component';
import { MarqueeComponent } from './marquee/marquee/marquee.component';
import { ModalComponent } from './modal/modal.component';
import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { PuzzleCaptchaComponent } from './puzzle-captcha/puzzle-captcha.component';
import { OptionsComponent } from './options/options.component';

@NgModule({
  declarations: [
    ModalComponent,
    PasswordStrengthComponent,
    PuzzleCaptchaComponent, 
    PreloaderComponent, 
    InlinePreloaderComponent, 
    ClearInputIconComponent, 
    CsChatIconComponent,
    BackButtonComponent,
    DynamicTemplateComponent,
    CollapsiblePanelComponent,
    OtpVerificationComponent,
    CustomModalComponent,
    MarqueeComponent,
    OptionsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DirectiveModule
  ],
  exports: [
    ModalComponent, 
    PreloaderComponent, 
    InlinePreloaderComponent,
    PasswordStrengthComponent,
    PuzzleCaptchaComponent,
    ClearInputIconComponent,
    CsChatIconComponent,
    BackButtonComponent,
    DynamicTemplateComponent,
    CollapsiblePanelComponent,
    OtpVerificationComponent,
    CustomModalComponent,
    MarqueeComponent,
    OptionsComponent
  ]
})
export class ComponentModule { }
