import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OptionsContent } from '../../models/options.model';

@Injectable({
  providedIn: 'root'
})
export class OptionsService {

  private optionsContent: Subject<OptionsContent> = new Subject<OptionsContent>();
  optionsContent$: Observable<OptionsContent> = this.optionsContent.asObservable();

  private optionsViewToggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  optionsDisplayToggle$: Observable<boolean> = this.optionsViewToggle.asObservable();

  private selectedOption: BehaviorSubject<string> = new BehaviorSubject<string>('');
  selectedOption$: Observable<string> = this.selectedOption.asObservable();

  constructor() { }

  showOptions(options: Array<string>, title: string, defaultOption: string) {
    this.optionsContent.next({
      options,
      title,
      default: defaultOption
    });

    if (!this.selectedOption.getValue()) {
      this.selectedOption.next(defaultOption);
    }

    this.openOptions();
  }

  selectOption(option: string): void {
    this.selectedOption.next(option);
  }

  openOptions(): void {
    this.optionsViewToggle.next(true);
  }

  closeOptions(): void {
    this.optionsViewToggle.next(false);
  }
}
