import { Component, OnDestroy } from '@angular/core';
import { CommonService } from '../../service/common/common.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { langxObj } from './options_langx';
import { LocalizedMessages } from '../../models/i18n.model';
import { OptionsContent } from '../../models/options.model';
import { OptionsService } from '../../service/options/options.service';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrl: './options.component.scss'
})
export class OptionsComponent implements OnDestroy {

  private destroy$: Subject<void> = new Subject();
  langx: LocalizedMessages = {};

  optionsContent$: Observable<OptionsContent>;
  optionsDisplayToggle$: Observable<boolean>;
  selectedOption: string = '';
  mediaServer: string = '';
  
  constructor(
    private commonService: CommonService,
    private optionsService: OptionsService
  ) {
    this.optionsContent$ = optionsService.optionsContent$;
    this.optionsDisplayToggle$ = optionsService.optionsDisplayToggle$;

    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLanguage => {
        this.langx = langxObj[currentLanguage];
      });

    this.optionsService.selectedOption$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option: string) => {
        this.selectedOption = option;
      });

    this.commonService.mediaServer$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      mediaServer => this.mediaServer = mediaServer
    );
  }

  onOptionCloseButtonClick(): void {
    this.optionsService.closeOptions();
  }

  selectOption(option: string): void {
    this.optionsService.selectOption(option);
    this.optionsService.closeOptions();
  }

  isOptionSelected(option: string): boolean {
    return this.selectedOption === option;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
