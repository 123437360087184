import { LocalizedMessagesBundle } from '../../models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'TITLE': 'Options',
    'CLOSE_BTN_TEXT': '关闭'
  },
  'en-gb': {
    'TITLE': 'Options',
    'CLOSE_BTN_TEXT': 'Close'
  }
}