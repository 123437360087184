@if(optionsContent$ | async; as content) {
<div class="options-wrapper" [class.show]="(optionsDisplayToggle$ | async) == true">
    <div class="backdrop" (click)="onOptionCloseButtonClick()"></div>
    <div class="options">
        <div class="options-header">
            <div class="text-very-small">{{ content.title || langx['TITLE'] }}</div>
        </div>

        <div class="options-body">
            <div class="options-list-wrapper">
                <div class="options-item text-blue" (click)="selectOption(content.default)"
                    [class.selected]="isOptionSelected(content.default)">
                    {{ content.default }}
                </div>

                @for(option of content.options; track $index) {
                    <div class="options-item text-blue" (click)="selectOption(option)"
                        [class.selected]="isOptionSelected(option)">
                        {{ option }}
                    </div>
                }
            </div>
        </div>
    </div>

    <div class="options-footer">
        <div class="btn-wrapper">
            <input (click)="onOptionCloseButtonClick()" class="btn text-small text-blue" type="button"
                [value]="langx['CLOSE_BTN_TEXT']" />
        </div>
    </div>
</div>
}